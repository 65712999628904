import { useNavigate } from 'react-router-dom';

import { useBrandStore } from 'stores';
import { useAuth0 } from '@auth0/auth0-react';
import { useBackgroundConditions, useFeatureFlag } from 'hooks';
import { useState } from 'react';
import NavBarView from './NavBar.View';
import { getNavbarBottomButtons, getNavbarTopButtons } from './helpers/navbarItems';
import useBrandHistory from './useBrandHistory';

const NavBar = () => {
  const { brandsHistory, loading } = useBrandHistory();
  const { brand, clearBrandStore } = useBrandStore();
  const [isLogingOut, setIsLogingOut] = useState(false);
  const { skipNavbar } = useBackgroundConditions();
  const { logout } = useAuth0();
  const navigate = useNavigate();
  const { debugMode } = useFeatureFlag();

  if (skipNavbar && !isLogingOut) return null;

  const onLogout = () => {
    logout({ returnTo: window.location.origin });
    setIsLogingOut(true);
    clearBrandStore();
  };

  const navbarTopButtons = getNavbarTopButtons(brand.brandId);

  const navbarBottomButtons = getNavbarBottomButtons({
    loading,
    brand,
    brands: brandsHistory,
  });

  let accountMenuItems = [
    {
      label: 'User profile',
      pathname: '/account/general',
      trailingDivider: true,
      onClick: () => navigate('/account/general'),
    },
    {
      label: 'Help center',
      pathname: '',
      trailingDivider: true,
      onClick: () => window.open('http://help.proxima.ai/en/'),
    },
    {
      label: 'Log out',
      pathname: '',
      onClick: onLogout,
    },
  ];

  // console.log({ debugMode });

  if (debugMode) {
    accountMenuItems = [
      {
        label: 'Debug',
        pathname: '/debug',
        trailingDivider: true,
        onClick: () => navigate('/debug'),
      },
      ...accountMenuItems,
    ];
  }

  return (
    <NavBarView
      accountMenuItems={accountMenuItems}
      navbarTopButtons={navbarTopButtons}
      navbarBottomButtons={navbarBottomButtons}
    />
  );
};

export default NavBar;
