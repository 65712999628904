import { useAuth0 } from '@auth0/auth0-react';
import { LDProvider, ProviderConfig } from 'launchdarkly-react-client-sdk';
import { useBrandStore } from 'stores';

/*
  only meant to be used within the brand loader
  see: src/Router/BrandLoader/BrandLoader.tsx
*/
const FeatureFlagProvider: React.FC<{}> = ({ children }) => {
  const { isLoading, user } = useAuth0();
  const { brand } = useBrandStore();

  if (isLoading) return null;

  if (!user || !user.sub) return <>{children}</>;

  if (!brand.brandId) return <>{children}</>;

  const providerState: ProviderConfig = {
    clientSideID: import.meta.env.VITE_LD_CLIENT_SIDE_ID,
    context: {
      kind: 'brand',
      key: brand.brandId,
      name: brand.name,
    },
    options: {
      streaming: true, // TODO(Jenky): To be tested, see what the team thinks
    },
    timeout: 4999,
  };

  return <LDProvider {...providerState}>{children}</LDProvider>;
};

export default FeatureFlagProvider;
